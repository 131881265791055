import { BatterDatePickerValue } from "../types/core";

export const AUTH_ERROR_CODES = new Set([401, 403]);

export const EMPTY_DATE_SELECTION: BatterDatePickerValue = { startDate: null, endDate: null, tbd: false };

export const MimeTypes = {
  JSON: "application/json",
  EXCEL_03: "application/vnd.ms-excel",
  EXCEL_07: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};
